

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppointmentView } from '../types';

@Component({})
export default class PluginList extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: AppointmentView[];
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  lines(item: AppointmentView): string {
    let height = 2;

    if (item.car) {
      height = height + 1;
    }

    if (item.conservatees.length > 0) {
      height = height + 1;
    }

    return `line-${height}`;
  }
}
